import React from 'react';
import { graphql } from 'gatsby';
import { Card, Row, Col } from 'react-bootstrap';
import TextTruncate from 'react-text-truncate';
import { Link, Image, SquareImage } from '../../common';
import ArticleLabel from '../ArticleLabel/ArticleLabel';
import { formatDateMMMDYYYY } from '../../lib/helpers/date';

export const query = graphql`
  fragment article on Drupal_NodePost {
    entityId
    title
    created
    entityUrl {
      path
    }
    fieldPageTitle
    entityCreated
    fieldNewsType {
      entity {
        ... on Drupal_TaxonomyTermNewsTypes {
          name
        }
      }
    }
    fieldFeaturedMedia {
      entity {
        ... on Drupal_MediaImage {
          entityId
          fieldMediaImage {
            alt
            width
            drupalURL: url
            entity {
              filemime
            }
          }
        }
      }
    }
    fieldAuthoredSections {
      entity {
        entityId
        entityBundle
        ...authoredText
      }
    }
  }
`;

const ArticleCard = ({
  article: {
    entityCreated,
    entityUrl: { path: url },
    fieldAuthoredSections,
    fieldFeaturedMedia,
    fieldNewsType: {
      entity: { name: newsType },
    },
    fieldPageTitle,
  },
  className = '',
  headerAs = 'h2',
  horizontal = false,
  pinned,
  truncateLines = 3,
  files,
}) => {
  const text = fieldAuthoredSections
    .find(({ entity: { entityBundle } }) => entityBundle === 'text') // find the first text field
    ?.entity?.fieldText?.processed?.replace(/(<([^>]+)>)/gi, ''); // strip html tags

  return (
    <Card className={`${className} article-card` + (horizontal ? ' layout-horizontal' : '')}>
      {pinned && fieldFeaturedMedia && <FeaturedImg {...{ fieldFeaturedMedia, horizontal, files }} />}

      <Card.Body>
        <Row className={'align-items-center mb-3' + (horizontal ? '' : ' mb-md-7')}>
          <Col md={horizontal ? 12 : 8} className={'mb-4' + (horizontal ? '' : ' mb-md-0')}>
            <ArticleLabel type={newsType} />
          </Col>
          <Col className={'text-muted' + (horizontal ? '' : ' text-md-right')}>{formatDateMMMDYYYY(entityCreated)}</Col>
        </Row>

        <Card.Title as={headerAs} className={`mb-3 ` + (pinned ? 'h4' : 'h5')}>
          {fieldPageTitle}
        </Card.Title>

        <Card.Text className={`mb-4 font-weight-normal ` + (pinned ? 'lead' : 'lead-2')}>
          <TextTruncate line={truncateLines} element="span" truncateText="…" text={text} />
        </Card.Text>

        <Card.Link as={Link} href={url} className="stretched-link lead-2">
          Read more
        </Card.Link>
      </Card.Body>
    </Card>
  );
};

const FeaturedImg = ({ horizontal, fieldFeaturedMedia: { entity }, files }) => {
  const {
    fieldMediaImage: {
      width,
      entity: { filemime },
    },
  } = entity;

  return (
    <>
      <Card.Body className="featured-img-wrapper">
        {filemime === 'image/svg+xml' || (horizontal && width > 420) || width > 600 ? (
          <SquareImage entity={entity} files={files} />
        ) : (
          // don't crop extra small non svg images
          <Image entity={entity} files={files} />
        )}
      </Card.Body>
    </>
  );
};

export default ArticleCard;
