import * as React from 'react';
import { graphql } from 'gatsby';

import { LeftContainer, MainContainer, PageContainer, Link } from '../../common';
import NewsMenu from '../../components/NewsMenu/NewsMenu';
import ArticleCard from '../../components/Card/ArticleCard';
import Intro from '../../components/Intro/Intro';
import Pagination from '../../components/Pagination/Pagination';
import { styleVars } from '../../lib/helpers/style';

const { mobileBreakpoint } = styleVars;

export const query = graphql`
  fragment postHubContent on Drupal_NodePostHub {
    fieldIntro {
      entity {
        ...introBlurb
      }
    }
    fieldNewsFilters {
      entity {
        name
      }
    }
    fieldMainNewsPage
    entityUrl {
      ... on Drupal_EntityCanonicalUrl {
        breadcrumb {
          url {
            path
          }
        }
      }
    }
  }
  query($nid: String!, $pagePathRegex: String!) {
    drupal {
      nodeById(id: $nid) {
        ...postHubContent
      }
      nodeQuery(
        limit: 1000
        filter: {
          conditions: [
            { field: "type", value: ["post"], operator: EQUAL }
            { field: "status", value: "1", operator: EQUAL }
          ]
        }
        sort: { field: "created" }
      ) {
        entities {
          ...article
          ... on Drupal_NodePost {
            entityId
            fieldNewsType {
              entity {
                ... on Drupal_TaxonomyTermNewsTypes {
                  name
                }
              }
            }
          }
        }
      }
      homePage: nodeQuery(filter: { conditions: [{ field: "type", value: "home_page", operator: EQUAL }] }) {
        entities {
          ... on Drupal_NodeHomePage {
            fieldPinnedPost {
              entity {
                entityId
              }
            }
          }
        }
      }
    }
    allSitePage(filter: { path: { regex: $pagePathRegex } }) {
      nodes {
        path
        context {
          nid
        }
        children {
          ...childFiles
        }
      }
    }
  }
`;

const PostHub = ({
  data: {
    drupal,
    allSitePage: { nodes: posts },
  },
  pageContext: { pagePath, pagePathRegex },
}) => {
  const {
      nodeById: node,
      nodeQuery: { entities },
      homePage: { fieldPinnedPost },
    } = drupal,
    {
      fieldIntro,
      fieldNewsFilters,
      fieldMainNewsPage,
      entityUrl: { breadcrumb },
    } = node,
    newsTypes = fieldNewsFilters.map(({ entity }) => entity.name),
    newsLink = breadcrumb[[breadcrumb.length - 1]];

  let articles = entities.filter(({ fieldNewsType }) => newsTypes.includes(fieldNewsType.entity.name));
  let files = [];

  if (fieldPinnedPost && fieldMainNewsPage) {
    const pinnedId = fieldPinnedPost.entity.entityId;

    articles = articles.reduce(
      (acc, e) => (pinnedId === e.entityId ? [{ ...e, pinned: true }, ...acc] : [...acc, e]),
      []
    );

    files = posts.find(({ context: { nid } }) => nid === pinnedId)?.children;
  }

  const renderData = ({ pinned = false, entityId, ...article }) => (
    <ArticleCard article={article} headerAs="h2" key={entityId} pinned={pinned} files={files} />
  );

  return (
    <>
      <MainContainer row className={`pt-4 pt-${mobileBreakpoint}-9 pb-7`}>
        <LeftContainer className={`pt-${mobileBreakpoint}-2`}>
          <NewsMenu />
        </LeftContainer>

        <PageContainer className={`pt-8 pt-${styleVars.mobileBreakpoint}-0 d-flex flex-column justify-content-between`}>
          {fieldIntro && <Intro entity={fieldIntro} className="mb-3" />}

          {!fieldMainNewsPage && (
            <Link href={newsLink.url.path} className="mb-2">
              View all news
            </Link>
          )}
        </PageContainer>
      </MainContainer>

      <div className="post-hub pt-8 pb-13">
        <MainContainer row>
          <LeftContainer />
          <PageContainer>
            {articles.length ? (
              <Pagination data={articles} renderData={renderData} currentPath={pagePath} perPage={10} />
            ) : (
              <p>No posts found.</p>
            )}
          </PageContainer>
        </MainContainer>
      </div>
    </>
  );
};

export default PostHub;
